/* eslint-disable */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import grey from "@material-ui/core/colors/grey";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _find from "lodash/find";
import _toLower from "lodash/toLower";
import _trim from "lodash/trim";

import IssueNewBadge from "./issue_new";
import ListRecipients from "./recipients";
import BadgeDetails from "../BadgeDetailsPage/details";
import CertificateDetails from "../CertificatePage/details";

import Pagination from "../../components/Pagination";
import TableBar from "../../components/TableBar";
// import ModelDelete from '../../components/ModalDelete';
import ModalView from "../../components/ModalView";
// import FormRadio from '../../components/FormRadio';
// import FormInput from '../../components/FormInput';
// import FormSelect from '../../components/FormSelect';
import DotsLoader from "../../components/DotsLoader";

import { InfoButton, ErrorButton, ButtonGroup, InverseButton, IndigoButton, WarningButton } from "../../styles/button";
import { SuccessTag, AmberTag } from "../../styles/tag";
import { WrapWord } from "../../styles/misc";

import { isArrayExists } from "../../helpers/validation";
import { triggerErrorAlert } from "../../helpers/alert";
import { cloneCollections, doPagination, getSelectOptions, doArraySearch, getSelectedValue } from "../../helpers/data";
import { getMomentTime } from "../../helpers/date";
import { getAPIErrorMessage } from "../../helpers/action";
import { isAdmin, hasAccessRights } from "../../helpers/auth";

import { fb_getCertificate } from "../../actions/m_certificates/fb";

import { DEV_MODE } from "../../constants";

const CertWrapper = styled.div`
    .certificate-grid {
        border: 2px solid #212121;
    }
`;

const CertificateLoader = ({ open = false, selectedCert = false }) => {
    const [certificate, setCertificate] = useState(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (open) {
            setLoaded(false);
            if (selectedCert) {
                fb_getCertificate(selectedCert)
                    .then((result) => {
                        setCertificate(result);
                        setLoaded(true);
                    })
                    .catch((error) => {
                        triggerErrorAlert(getAPIErrorMessage(error));
                    });
            }
        }
    }, [open]);

    return loaded ? (
        <CertWrapper>
            <CertificateDetails previewMode={true} certificate={certificate || false} />
        </CertWrapper>
    ) : (
        <DotsLoader />
    );
};

const useStyles = (theme) => ({
    headcell: {
        fontSize: "16px",
        fontWeight: "700",
        color: theme.palette.background,
    },
    bodycell: {
        fontSize: "16px",
        verticalAlign: "top",
    },
});

class List extends React.Component {
    state = {
        sortBy: "created_on-desc",
        filterBy: "all",
        perPage: 20,
        page: 1,
        openIssueNew: false,
        openBadgePreview: false,
        selectedBadge: false,
        openViewRecipients: false,
        selectedReport: false,
        openCertPreview: false,
        selectedCert: false,
    };

    reorganizeData() {
        const { sortBy, perPage, page, searchterms } = this.state;
        const { list, badge } = this.props;
        var items = list ? cloneCollections(list) : [],
            total = _size(items);

        // do search
        if (searchterms && !_isEmpty(searchterms)) {
            items = doArraySearch(items, searchterms, ["name"]);
            total = _size(items);
        } // end - searchterms

        if (badge && !_isEmpty(badge) && badge !== "all") {
            items = _filter(items, { badge_id: badge });
            total = _size(items);
        }

        if (this.state.filterBy !== "all") {
            items = _filter(items, { status: this.state.filterBy });
            total = _size(items);
        }

        // do sort
        if (sortBy && !_isEmpty(sortBy) && !_isEmpty(items)) {
            switch (sortBy) {
                case "name-desc":
                    items = _sortBy(items, [(i) => (i.name && i.name.toLowerCase()) || ""]);
                    items = _reverse(items);
                    break;
                case "name-asc":
                    items = _sortBy(items, [(i) => (i.name && i.name.toLowerCase()) || ""]);
                    break;
                case "created_on-desc":
                    items = _sortBy(items, ["created_on"]);
                    items = _reverse(items);
                    break;
                case "created_on-asc":
                    items = _sortBy(items, ["created_on"]);
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination(items, perPage, page);

        return { items, total };
    }

    renderBadgePreview = () => {
        const { selectedBadge } = this.state;
        return (
            <div>
                <BadgeDetails preview={true} badge={selectedBadge || false} />
            </div>
        );
    };

    renderCertPreview = () => {
        const { selectedCert, openCertPreview } = this.state;
        return <CertificateLoader open={openCertPreview} selectedCert={selectedCert} />;
    };

    renderTableActions = () => {
        const { sortBy, perPage, searchterms, filterBy } = this.state;
        return (
            <TableBar
                // show={['sort','entries']}
                filterBy={filterBy}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: "created_on-desc", label: "Most recent first" },
                    { value: "created_on-asc", label: "Oldest entries first" },
                    { value: "name-asc", label: "Title ( A - Z)" },
                    { value: "name-desc", label: "Title ( Z - A )" },
                ]}
                filterByOptions={[
                    { value: "all", label: "All Status" },
                    { value: "draft", label: "Draft" },
                    { value: "issued", label: "Issued" },
                ]}
                rightButtons={[
                    <InfoButton minWidth="128px" style={{ marginRight: "5px" }} onClick={() => this.setState({ openIssueNew: true })}>
                        Issue New
                    </InfoButton>,
                ]}
                onFilterByChange={(newFilterBy) =>
                    this.setState({
                        filterBy: newFilterBy === "" ? "all" : newFilterBy,
                        page: 1,
                    })
                }
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                style={{ marginBottom: "20px" }}
            />
        );
    };

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return (
            <Pagination
                total={totalCount}
                perPage={perPage}
                page={page}
                doneLoaded={true}
                style={{ marginTop: "20px" }}
                onPageChange={(newPage) => this.setState({ page: newPage })}
            />
        );
    };

    renderBody = (items) => {
        const { classes, badges, certificates } = this.props;
        return (
            <TableBody>
                {items && isArrayExists(items) ? (
                    items.map((item) => {
                        const selected_badge = _find(badges, { id: item.badge_id });
                        return (
                            <TableRow key={item.id} id={item.id}>
                                <TableCell className={classes.bodycell}>
                                    {item.status && item.status === "draft" ? <AmberTag>Draft</AmberTag> : <SuccessTag>Issued</SuccessTag>}
                                </TableCell>
                                <TableCell className={classes.bodycell}>
                                    <WrapWord>{item.name || ""}</WrapWord>
                                    {DEV_MODE && (
                                        <div>
                                            <small style={{ fontSize: "0.75em" }}>ID: {item.id}</small>
                                        </div>
                                    )}
                                </TableCell>
                                <TableCell className={classes.bodycell}>
                                    <WrapWord>{getSelectedValue(badges || [], (item && item.badge_id) || false, "id", "name", "---")}</WrapWord>
                                </TableCell>
                                <TableCell className={classes.bodycell}>
                                    <WrapWord>
                                        {getSelectedValue(
                                            certificates || [],
                                            (selected_badge && selected_badge.cert_referer) || false,
                                            "id",
                                            "label",
                                            "---"
                                        )}
                                    </WrapWord>
                                </TableCell>
                                <TableCell className={classes.bodycell}>
                                    <WrapWord>{item.total_recipients || "0"}</WrapWord>
                                </TableCell>
                                <TableCell className={classes.bodycell}>
                                    {item.status && item.status === "draft" ? (
                                        "---"
                                    ) : (
                                        <WrapWord>{getMomentTime(item.created_on, "YYYY-MMM-DD hh:mma")}</WrapWord>
                                    )}
                                </TableCell>
                                <TableCell className={classes.bodycell}>
                                    <ButtonGroup>
                                        <IndigoButton
                                            size="small"
                                            onClick={() =>
                                                this.setState({
                                                    openBadgePreview: true,
                                                    selectedBadge: _find(badges, { id: item.badge_id }),
                                                })
                                            }
                                        >
                                            <i className="fa fa-search"></i>Preview Badge
                                        </IndigoButton>
                                        <IndigoButton
                                            size="small"
                                            onClick={() =>
                                                this.setState({
                                                    openCertPreview: true,
                                                    selectedCert: selected_badge && selected_badge.cert_referer ? selected_badge.cert_referer : false,
                                                })
                                            }
                                        >
                                            <i className="fa fa-search"></i>Preview Cert
                                        </IndigoButton>
                                        <WarningButton
                                            size="small"
                                            onClick={() =>
                                                this.setState({
                                                    openIssueNew: true,
                                                    selectedReport: {
                                                        ...item,
                                                        status: "duplicate",
                                                    },
                                                })
                                            }
                                        >
                                            <i className="fa fa-copy"></i>Duplicate
                                        </WarningButton>
                                        {item.status && item.status === "draft" ? (
                                            <>
                                                <InfoButton
                                                    size="small"
                                                    onClick={() => {
                                                        this.setState({
                                                            openIssueNew: true,
                                                            selectedReport: item,
                                                        });
                                                    }}
                                                >
                                                    <i className="fa fa-edit"></i>Edit Draft
                                                </InfoButton>
                                            </>
                                        ) : (
                                            <>
                                                <InverseButton
                                                    size="small"
                                                    onClick={() =>
                                                        this.setState({
                                                            openViewRecipients: true,
                                                            selectedReport: item,
                                                        })
                                                    }
                                                >
                                                    <i className="fa fa-users"></i>View Recipients
                                                </InverseButton>
                                            </>
                                        )}
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        );
                    })
                ) : (
                    <TableRow>
                        <TableCell className={classes.bodycell}>No item(s) found.</TableCell>
                    </TableRow>
                )}
            </TableBody>
        );
    };

    renderHeader = () => {
        const { classes } = this.props;
        return (
            <TableHead>
                <TableRow>
                    <TableCell className={classes.headcell}>Status</TableCell>
                    <TableCell className={classes.headcell}>Title</TableCell>
                    <TableCell className={classes.headcell}>Badge</TableCell>
                    <TableCell className={classes.headcell}>Certificate</TableCell>
                    <TableCell className={classes.headcell}>Total Recipient(s)</TableCell>
                    <TableCell className={classes.headcell}>Issued On</TableCell>
                    <TableCell className={classes.headcell}>Actions</TableCell>
                </TableRow>
            </TableHead>
        );
    };

    render() {
        const { badges, certificates, onIssueNew = () => {} } = this.props;
        const { openIssueNew, openBadgePreview, openViewRecipients, selectedBadge, selectedReport, openCertPreview, selectedCert } = this.state;
        const { items, total } = this.reorganizeData();
        return (
            <div>
                {this.renderTableActions()}
                <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                    <Table>
                        {this.renderHeader()}
                        {this.renderBody(items)}
                    </Table>
                </Paper>
                {this.renderPagination(total)}

                <ModalView
                    open={openBadgePreview ? true : false}
                    title="Badge Preview"
                    maxWidth="lg"
                    noAction={true}
                    cancelLabel="Close"
                    onClose={() => this.setState({ openBadgePreview: false, selectedBadge: false })}
                    contents={this.renderBadgePreview()}
                />

                <ModalView
                    open={openCertPreview ? true : false}
                    title="Certificate Preview"
                    maxWidth="lg"
                    noAction={true}
                    cancelLabel="Close"
                    onClose={() => this.setState({ openCertPreview: false, selectedCert: false })}
                    contents={this.renderCertPreview()}
                />

                <IssueNewBadge
                    open={openIssueNew}
                    report={selectedReport}
                    badges={badges}
                    certificates={certificates}
                    onBadgePreview={(badge_id) => this.setState({ openBadgePreview: true, selectedBadge: _find(badges, { id: badge_id }) })}
                    onSubmit={onIssueNew}
                    onClose={() => this.setState({ openIssueNew: false, selectedReport: false })}
                />

                <ListRecipients
                    open={openViewRecipients}
                    report={selectedReport}
                    badges={badges}
                    certificates={certificates}
                    onClose={() => this.setState({ openViewRecipients: false, selectedReport: false })}
                />
            </div>
        );
    }
}

export default compose(connect(), withStyles(useStyles), withRouter)(List);
